import { useQuery } from "react-query";
import axios from "axios";
import { AuthContext } from "../../../context/authcontext";
import { useContext } from "react";

const useMessages = (page = 1) => {
  const { token } = useContext(AuthContext);

  const { isLoading, isError, data, error, refetch } = useQuery(["messages", page], async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/msgs/getall-public-messages?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });

  return {
    isLoading,
    isError,
    data,
    error,
    refetch,
  };
};

export default useMessages;
