import { ReactComponent as LogoIcon } from "../../assets/Vector.svg";
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useState } from "react"; 
import useLogin from "../libs/LoginHook";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { mutate, isLoading, error } = useLogin(); 

  const [isShow, setIsShow] = useState(false); 

  const togglePasswordVisibility = () => {
    setIsShow((prevIsShow) => !prevIsShow);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userData = { email, password };
      localStorage.setItem('userData', JSON.stringify(userData));
      await mutate({ email, password }); 
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="auth-container">
      <div className="logo">
        <LogoIcon />
      </div>
      <div className="login-form">
        <p>Welcome Back</p>
        <form onSubmit={handleLogin}>
          <label>
            Email Address
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label>
            Password
            <span>
              <input
                type={isShow ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {isShow ? (
                <PiEyeThin
                  onClick={togglePasswordVisibility}
                  className="icon"
                />
              ) : (
                <PiEyeSlashThin
                  onClick={togglePasswordVisibility}
                  className="icon"
                />
              )}
            </span>
            <Link to="/requestresetpassword">Forgot Password?</Link> 
          </label>
          {error && <div className="error">{error.message}</div>}

          <button disabled={isLoading}>
            {isLoading ? "authenticating..." : "Log in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
