import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../context/authcontext';

const deleteImages = async (ids, token) => {
  const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/media/images`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ids },
  });
  return response.data;
};

const useDeleteImages = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation((ids) => deleteImages(ids, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('image');
    },
  });
};

export default useDeleteImages;
