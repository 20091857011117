
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/authcontext'; 

const createYear = async (formData, token) => {
  const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/media/upload/imagecover`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    },
  });
  return response.data;
};

export const useCreateYear = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation((formData) => createYear(formData, token), {
    onSuccess: () => {
      queryClient.invalidateQueries('years');
    },
  });
};
