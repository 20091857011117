import React, { useState, useRef, useContext } from "react";
import useOneTimeVerification from "../libs/useOnetimeHook";
import useLogin from "../libs/LoginHook";
import { ReactComponent as LogoIcon } from "../../assets/Vector.svg";
import { AuthContext } from "../../context/authcontext";
import { useNavigate } from "react-router-dom";
import "./otp.css";

const OneTimeVerification = () => {
  const [digits, setDigits] = useState(["", "", "", ""]);
  const [isOptSent, setIsOtpSent] = useState(false);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const SendOtpMutate = useOneTimeVerification(); 
  const { mutate: loginUser, isLoading: loginLoading, error: loginError } = useLogin(); 
  const { Login } = useContext(AuthContext);
  const navigate = useNavigate();

  // Retrieve email from local storage
  const userDataString = localStorage.getItem('userData');
  const { email, password } = userDataString ? JSON.parse(userDataString) : { email: '', password: '' };

  const maskedEmail = (email) => {
    const atIndex = email.indexOf('@');
    const localPart = email.substring(0, atIndex);
    const maskedPart = localPart.substring(2, localPart.length - 2);
    return email.replace(maskedPart, '...');
  };

  const handleInputChange = (index, value) => {
    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleGetVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await SendOtpMutate.mutateAsync({ email });
      setIsOtpSent(true);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const activationCode = digits.join('');
      const { userId, token } = await loginUser({ email, password, otp: activationCode });
      const user = { userId, token };
      Login(user);
      navigate("/");
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    }
  };

  return (
    <div className="one-time-verification">
      <div className="banner">
        <LogoIcon />
      </div>
      <div className="wrapper">
        {!isOptSent && (
          <>
            <h3>Verify Email Address</h3>
            <p>Send a verification code to {maskedEmail(email)}.</p>
            <div className="btn">
              <button type="submit" onClick={handleGetVerification} disabled={loading}>
                {loading ? "Sending..." : "Send Code"}
              </button>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </>
        )}
        {isOptSent && (
          <form onSubmit={handleLogin}>
            <p>Enter 4-digit code sent to your Email</p>
            <div className="token-inputs">
              {digits.map((digit, index) => (
                <span key={index}>
                  <input
                    ref={inputRefs[index]}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </span>
              ))}
            </div>
            <button type="submit" disabled={loginLoading}>
              {loginLoading ? "authorizing..." : "Log In"}
            </button>
            {loginError && <p style={{ color: "red" }}>{loginError.message}</p>}
            <p>
              Didn't get the code?, <span>Resend</span> in 30secs
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default OneTimeVerification;

