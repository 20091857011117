import { useQuery } from "react-query";
import axios from "axios";

const useSpeech = (page = 1) => {
  const { isLoading, isError, data, error, refetch } = useQuery(["news", page], async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/speech/speeches?page=${page}`
    );
    return response.data;
  });

  return {
    isLoading,
    isError,
    data,
    error,
    refetch, 
  };
};

export default useSpeech;



