import { ReactComponent as LogoIcon } from "../../assets/Vector.svg";
import { useState } from "react";
import useResetPasswordMutation from "../libs/useResetPasswordMutation";
import { useNavigate } from "react-router-dom";

import "./requesstpassword.css";

const RequestRestpassword = () => {
  const [email, setEmail] = useState('');
  const resetPasswordMutation = useResetPasswordMutation(); 
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await resetPasswordMutation.mutateAsync(email);
      navigate('/resetpassword');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return ( 
    <div className="request-pass-reset">
      <div className="logo">
        <LogoIcon/>
      </div>
      <div className="reset-container">
        <h3>Forgot password</h3>
        <form onSubmit={handleResetPassword}>
          <label>
            Email Address
            <input 
              type="email"  
              value={email}
              onChange={e => setEmail(e.target.value)}
              required 
            />
          </label>
          {error && <p className="error">{error}</p>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "validating..." : "Confirm"}
          </button>
        </form>
      </div>
    </div>
  );
};
 
export default RequestRestpassword;
