import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../../context/authcontext';
import { useContext } from 'react';

const deleteNewsletters = async (ids, token) => {
  const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/sendnewsletter/delete`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ids },
  });
  return response.data;
};

const useDeleteNewsletters = () => {
  const { token } = useContext(AuthContext)
  const queryClient = useQueryClient();

  return useMutation(
    (ids) => deleteNewsletters(ids, token),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('newsletters');
      },
      onError: (error) => {
        console.error('Error deleting newsletters:', error);
      },
    }
  );
};

export default useDeleteNewsletters;
