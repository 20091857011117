import { CiCirclePlus } from "react-icons/ci";
import { Fragment, useState } from "react";
import { ReactComponent as ImgIcon } from "../../../assets/uim_image-v.svg";
import { useCreateYear } from "../../../medialibs/useCreateYear";
import { useFetchYears } from "../../../medialibs/useFetchYears";
import { RxDotsVertical } from "react-icons/rx";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./media.css";

const Media = () => {
  const [year, setYear] = useState('');
  const [imageCovers, setImageCovers] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [addTestivalOverlay, setFestivalOverlay] = useState(false);
  const createMutation = useCreateYear();
  const { data: years, isLoading: isYearsLoading, isError: isYearsError } = useFetchYears();

  const handleFileChange = (event) => {
    const files = event.target.files;
    setImageCovers(files);

    const previews = Array.from(files).map(file => URL.createObjectURL(file));
    setPreviewUrls(previews);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('year', year);
    Array.from(imageCovers).forEach((file) => {
      formData.append('imageCover', file);
    });

    createMutation.mutate(formData, {
      onSuccess: (data) => {
        setFestivalOverlay(false);
        setYear("");
        setImageCovers([]);
        setPreviewUrls([]);
        console.log('Year created successfully:', data);
        // Handle success (e.g., reset form, show notification, etc.)
      },
      onError: (error) => {
        console.error('Error creating year:', error);
        // Handle error (e.g., show error message)
      },
    });
  };

  return (
    <Fragment>
      {addTestivalOverlay && (
        <div className="festival-container">
          <div className="card">
            <h3>Add Year</h3>
            <form onSubmit={handleSubmit}>
              <label>
                Year
                <input 
                  type="text" 
                  value={year} 
                  onChange={(e) => setYear(e.target.value)} 
                  required 
                />
              </label>
              <div className="image-card">
                <div className="in">
                  <h3>Year Cover Image</h3>
                </div>
                <div className="picker">
                  {previewUrls.length === 0 ? (
                    <Fragment>
                      <input 
                        type="file" 
                        multiple 
                        onChange={handleFileChange} 
                        accept="image/jpeg, image/png" 
                        style={{ display: 'none' }} 
                        id="imageCoverInput"
                      />
                      <label htmlFor="imageCoverInput">
                        <ImgIcon />
                        <span>Drop your image here, or <h3>Upload</h3></span>
                        <p>Supports JPG, JPEG2000, PNG</p>
                      </label>
                    </Fragment>
                  ) : (
                    previewUrls.map((url, index) => (
                      <img key={index} src={url} alt={`Preview ${index}`} className="image-preview" />
                    ))
                  )}
                </div>
              </div>
              {createMutation.isError && <p>Error: {createMutation.error.message}</p>}
              <div className="btn">
                <button type="button" onClick={() => setFestivalOverlay(false)}>Cancel</button>
                <button type="submit" disabled={createMutation.isLoading}>
                  {createMutation.isLoading ? 'Save...' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="media-container">
        <div className="header">
          <h3>Media</h3>
          <button onClick={() => setFestivalOverlay(true)}>
            <CiCirclePlus className="icon" /> Add year
          </button>
        </div>
        <div className="years-container">
        {isYearsLoading ? (
            <>
            <Skeleton count={4} height={100} width={250} />
            <Skeleton count={4} height={100} width={250} />
            <Skeleton count={4} height={100} width={250} />
            <Skeleton count={4} height={100} width={250} />
          </>
          ) : isYearsError ? (
            <p className="error">No data available for years</p>
          ) : years.length === 0 ? (
            <p className="error">No years found.</p>
          ) : (
            years && years.map((year) => (
              <Link  key={year._id} to={`/year/${year._id}`} className="card">
                {year.imageCover.map((cover, index) => (
                  <img key={index} src={cover} alt={`Year cover ${index}`} className="year-cover" />
                ))}
                <div className="wrap">
                <h4>{year.year}</h4>
                <RxDotsVertical />
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Media;
