import { useMutation } from 'react-query';

const OneTimeVerification = async ({ email }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/request-One-Time-Verification-Code`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to send for otp');
  }

  return response.json();
};

const useOneTimeVerification = () => {
  return useMutation(OneTimeVerification); 
};

export default useOneTimeVerification;
