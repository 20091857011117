import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../context/authcontext';
import { useContext } from 'react';

const addImageToFestival = async ({ token,festivalId, formData }) => {
  const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/media/addimage/${festivalId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    },
  });
  return response.data;
};

export const useAddImageToFestival = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation(
    ({ festivalId, formData }) => addImageToFestival({ festivalId, formData, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('festival'); 
      },
    }
  );
};
