import { useQuery } from "react-query";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../../context/authcontext";

const useNewsletter = (page = 1) => {
  const { token } = useContext(AuthContext);

  const { isLoading, isError, data, error, refetch } = useQuery(["newsletters", page], async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/sendnewsletter/get/newsletters?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });

  return {
    isLoading,
    isError,
    data,
    error,
    refetch, 
  };
};

export default useNewsletter;
