import { ReactComponent as LogoIcon } from "../../assets/Vector.svg";
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi";
import { useState } from "react";
import useResetPasswordMutation from "../libs/Resetpass";
import { useNavigate } from "react-router-dom";
import "./ressetpass.css";

const ResetPassword = () => {
  const [isShow, setIsShow] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMsg, setSuccsMsg] = useState(false)
  const resetPasswordMutation = useResetPasswordMutation();
  const navigate = useNavigate()

  const togglePasswordVisibility = () => {
    setIsShow((prevIsShow) => !prevIsShow);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await resetPasswordMutation.mutateAsync({ resetCode, newPassword });
      setSuccsMsg(true)
      setTimeout(() => {
        setSuccsMsg(false)
          navigate('/');
      }, 3000);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return ( 
    <div className="reset-pass-container">
      <div className="logo">
        <LogoIcon />
      </div>
      <div className="reset-container">
        <h3>Reset password</h3>
        <form onSubmit={handleResetPassword}>
          <label>
            Reset Code
            <input type="number" value={resetCode} onChange={(e) => setResetCode(e.target.value)} />
          </label>
          <label>
            New Password
            <span>
              <input type={isShow ? "text" : "password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              {isShow ? (
                <PiEyeThin onClick={togglePasswordVisibility} className="icon" />
              ) : (
                <PiEyeSlashThin onClick={togglePasswordVisibility} className="icon" />
              )}
            </span>
          </label>
          {error && <p className="error-message">{error}</p>}
          {successMsg && <p className="success-msg">Password reset successfully you can now log in!</p>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Validating..." : "Confirm"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
