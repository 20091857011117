import { useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { HiOutlineEye, HiOutlineEyeSlash } from 'react-icons/hi2';
import useChangePassword from '../libs/useChangePassword'; 
import './changepassword.css';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const mutation = useChangePassword();

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate({ oldPassword, newPassword });
  };

  return (
    <div className="change-password">
      <div className="header">
        <IoMdArrowBack onClick={() => navigate('/settings')} className="icon" />
        <h3>Change password</h3>
      </div>

      <form onSubmit={handleSubmit}>
        <label>
          Enter Old Password
          <span className="password-input">
            <input
              type={showOldPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            {showOldPassword ? (
              <HiOutlineEyeSlash
                onClick={() => setShowOldPassword(false)}
                className="icon"
              />
            ) : (
              <HiOutlineEye
                onClick={() => setShowOldPassword(true)}
                className="icon"
              />
            )}
          </span>
        </label>

        <label>
          Enter New Password
          <span className="password-input">
            <input
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {showNewPassword ? (
              <HiOutlineEyeSlash
                onClick={() => setShowNewPassword(false)}
                className="icon"
              />
            ) : (
              <HiOutlineEye
                onClick={() => setShowNewPassword(true)}
                className="icon" 
              />
            )}
          </span>
        </label>
        {mutation.isError && (
          <p className="error">
            {mutation.error.response && 
              mutation.error.response.data.message === 'Invalid old password.' 
              ? 'Invalid old password. Please try again.' 
              : 'An error occurred. Please try again later.'
            }
          </p>
        )}
        <button type="submit" disabled={mutation.isLoading}>
          {mutation.isLoading ? 'Saving...' : 'Save'}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
