import { useContext } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import {AuthContext} from "../../../context/authcontext" 
import { useGetCurrentUser } from "../../../lib/fetchCurrentUser";

const useChangePassword = () => {
  const { token,  Logout } = useContext(AuthContext);
  const { data: currentUserData } = useGetCurrentUser();
  const id = currentUserData.userId

  return useMutation(
    ({ oldPassword, newPassword }) =>
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/change-password/${id}`, { oldPassword, newPassword }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    {
      onSuccess: (response) => {
        Logout()
      },
      onError: (error) => {
        console.error('Error changing password', error);
      }
    }
  );
};

export default useChangePassword;
