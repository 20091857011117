import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import {AuthContext} from "../../../context/authcontext"

const useDeleteSpeech = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext); 

  const deleteSpeech = async (selectSpeechId) => {
    try {
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/speech/speeches/${selectSpeechId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete speech');
      }
      queryClient.invalidateQueries('news');

      return response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useMutation(deleteSpeech);
};

export default useDeleteSpeech;
