import React, { useState, useEffect, Fragment } from "react";
import useNews from "../../../newlibs/useNews";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CiSearch } from "react-icons/ci";
import { BsFilter } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CautionIcon } from "../../../assets/caution-svgrepo-com 1.svg";
import {ReactComponent as EditIcon} from "../../../assets/mynaui_edit.svg"
import {ReactComponent as TrashIcon} from "../../../assets/lets-icons_trash-light.svg"
import useDeleteSpeech from "../../../newlibs/useDeleteSpeech";
import useUpdateSpeech from "../../../newlibs/useUpdateSpeech";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./home.css";

function formatDate(date) {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(date).toLocaleDateString("en-GB", options);
}

const Home = () => {
  const navigate = useNavigate();
  const deleteSpeechMutation = useDeleteSpeech();
  const updateSpeechMutation = useUpdateSpeech();
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, isError, data, error, refetch } = useNews(currentPage);
  const [searchQuery, setSearchQuery] = useState("");
  const newsPerPage = 12;
  const [totalNewsCount, setTotalNewsCount] = useState(0);
  const [selectSpeechId, setSelectedSpeechId] = useState(null);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateOverlay, setUpdateOverlay] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState("")
  const [images, setImages] = useState(null);

  useEffect(() => {
    if (data && data.pagination) {
      setTotalNewsCount(data.totalNewsCount);
    }
  }, [data]);

  const fetchSpeechDetails = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/new/news/${id}`);
      const result = await response.json();
      if (response.ok) {
        setSubject(result.subject);
        setContent(result.content);
        setDate(result.date)
        setImages(result.images[0]); 
      } else {
        console.error("Error fetching speech details:", result.message);
      }
    } catch (error) {
      console.error("Error fetching speech details:", error);
    }
  };

  const handleEditSpeech = async (id) => {
    setSelectedSpeechId(id);
    await fetchSpeechDetails(id);
    setUpdateOverlay(true);
  };

  useEffect(() => {
    if (updateOverlay && selectSpeechId) {
      fetchSpeechDetails(selectSpeechId);
    }
  }, [updateOverlay, selectSpeechId]);

  const handleClickNext = () => {
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      refetch(`?page=${nextPage}`);
      return nextPage;
    });
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => {
      const prevPageNumber = Math.max(prevPage - 1, 1);
      refetch(`?page=${prevPageNumber}`);
      return prevPageNumber;
    });
  };

  const handleDeleteSpeech = async () => {
    setDeleteLoading(true);
    try {
      await deleteSpeechMutation.mutateAsync(selectSpeechId);
      setDeleteOverlay(false);
      setDeleteLoading(false);
      refetch();
    } catch (error) {
      console.error("Failed to delete speech:", error.message);
      setDeleteLoading(false);
    }
  };

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    setImages(file);
  };

  const handleUpdateSpeech = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    try {
      await updateSpeechMutation.mutateAsync({
        id: selectSpeechId,
        subject,
        content,
        date,
        images,
      });
      setUpdateOverlay(false);
      setUpdateLoading(false);
      setSubject('');
      setContent('');
      setDate('');
      setImages(null);
      refetch();
    } catch (error) {
      console.error('Failed to update speech:', error.message);
      setUpdateLoading(false);
    }
  };

  const currentNews = data?.news || [];
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;

  let paginatedNews = currentNews;

  if (searchQuery) {
    paginatedNews = paginatedNews.filter((newsItem) =>
      newsItem.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <Fragment>
      {updateOverlay && (
        <div className="update-news-cons">
          <div className="card">
            <form onSubmit={handleUpdateSpeech}>
              <input
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <input
                type="date"
                placeholder="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleChange}
                placeholder="Write your speech here..."
              />
              {images && (
                <img
                  src={typeof images === 'string' ? images : URL.createObjectURL(images)}
                  alt="Preview"
                  className="image-preview"
                />
              )}
              {!images && (
                <div className="wrap">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleSelectImage}
                  />
                  <p>Upload Image</p>
                </div>
              )}
              <div className="btn">
                <button type="button" onClick={() => setUpdateOverlay(false)}>
                  Cancel
                </button>
                <button type="submit" disabled={updateLoading}>
                  {updateLoading ? 'Updating...' : 'Update'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {deleteOverlay && (
        <div className="delete-news-con">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete this article?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDeleteSpeech}>
                {deleteLoading ? 'Deleting...' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="speeches-container">
        <div className="header">
          <h3>Speeches</h3>
          <div className="wrap">
            <span>
              <CiSearch className="icon" />
              <input
                type="search"
                placeholder="Search Speeches"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </span>
            <div className="fill">
              <BsFilter />
              <p>Filters</p>
            </div>
            <button onClick={() => navigate('/createnews')}>
              <CiCirclePlus className="icon" /> Add Speeches
            </button>
          </div>
        </div>

        <div className="news-card">
        {isError && error.response && error.response.data.message === 'No news found' && (
          <div className="error">No news found</div>
        )}
          {!isError && (
            <div className="wrapper">
              {isLoading ? (
                <>
                  <Skeleton count={4} height={100} width={250} />
                  <Skeleton count={4} height={100} width={250} />
                  <Skeleton count={4} height={100} width={250} />
                  <Skeleton count={4} height={100} width={250} />
                </>
              ) : (
                paginatedNews.map((newsItem) => (
                  <div key={newsItem._id} className="news-item">
                    <h2>{newsItem.subject}</h2>
                    <div className="inner">
                      <p>{newsItem?.date ? formatDate(newsItem.date) : ''}</p>
                      <div className="action">
                        <button onClick={() => handleEditSpeech(newsItem._id)}>
                         <EditIcon/> Edit
                        </button>
                        <button
                          onClick={() => {
                            setSelectedSpeechId(newsItem._id);
                            setDeleteOverlay(true);
                          }}
                        >
                        <TrashIcon/>  Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          <div className="pagination">
            <p>{`Showing ${indexOfFirstNews + 1} - ${Math.min(indexOfLastNews, totalNewsCount)} of ${totalNewsCount}`}</p>
            <div className="btn">
              <button onClick={handleClickPrev} disabled={currentPage === 1}>
                Previous
              </button>
              <button onClick={handleClickNext} disabled={indexOfLastNews >= totalNewsCount}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;

