import { BsFilter } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import { Fragment, useState, useEffect } from "react";
import { ReactComponent as CautionIcon } from "../../../assets/caution-svgrepo-com 1.svg";
import useMessages from "../libs/useFetchMessages";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDeleteMessages } from "../libs/useDeleteMessages";
import "./messages.css";

const Messagess = () => {
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [viewMsgOverlay, setViewOverlay] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(null);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 10;
  const { isLoading, isError, data, refetch } = useMessages(currentPage);
  const [totalMessagesCount, setTotalMessagesCount] = useState(0);
  const [isDeleting, setDeleting] = useState(false);
  const deleteMutation = useDeleteMessages();

  useEffect(() => {
    if (data && data.totalMessages) {
      setTotalMessagesCount(data.totalMessages);
    }
  }, [data]);

  const handleClickNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    refetch();
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    refetch();
  };

  const handleViewMessage = (message) => {
    setSelectedMsg(message);
    setViewOverlay(true);
  };

  const handleDeleteMessages = () => {
    setDeleting(true);
    deleteMutation.mutate(
      { messageIds: selectedMessages },
      {
        onSuccess: () => {
          setDeleting(false);
          setDeleteOverlay(false);
        },
        onError: () => {
          setDeleting(false);
        },
      }
    );
  };

  const handleSelectMessage = (messageId) => {
    setSelectedMessages((prevSelected) =>
      prevSelected.includes(messageId)
        ? prevSelected.filter((id) => id !== messageId)
        : [...prevSelected, messageId]
    );
  };

  const handleSelectAllMessages = () => {
    if (selectedMessages.length === currentMessages.length) {
      setSelectedMessages([]);
    } else {
      setSelectedMessages(currentMessages.map((msg) => msg._id));
    }
  };

  const currentMessages = data?.publicMessages || [];
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Fragment>
      {viewMsgOverlay && (
        <div className="view-msg-overlay">
          <div className="card">
            <div className="header">
              <div className="in">
                <h3>{selectedMsg?.fullname}</h3>
                <p>{selectedMsg?.email}</p>
              </div>
              <p>{formatDate(selectedMsg?.createdAt)}</p>
            </div>
            {selectedMsg && (
              <div className="inner">
                <p>{selectedMsg?.content}</p>
              </div>
            )}
            <div className="btn">
              <button onClick={() => setViewOverlay(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
      {deleteOverlay && (
        <div className="delete-msg-overlay">
          <div className="card">
            <CautionIcon />
            <h3>Delete</h3>
            <p>Are you sure you want to delete the selected messages?</p>
            <div className="btn">
              <button
                onClick={() => {
                  setDeleteOverlay(false);
                  setSelectedMsg(null);
                  setSelectedMessages([]);
                }}
              >
                No
              </button>
              <button
                onClick={handleDeleteMessages}
                disabled={selectedMessages.length === 0 || isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="messages-container">
        <div className="header">
          <h3>Messages</h3>
          <div className="wrap">
            <div className="fill">
              <BsFilter />
              <p>Filters</p>
            </div>
          </div>
        </div>

        <div className="messages-card">
          <div className="header">
            <span>
              <input
                type="checkbox"
                onChange={handleSelectAllMessages}
                checked={
                  selectedMessages.length === currentMessages.length &&
                  currentMessages.length > 0
                }
              />
              <p>Select All</p>
            </span>
            <button
              onClick={() => setDeleteOverlay(true)}
              disabled={selectedMessages.length === 0}
            >
              <ImBin className="icon" />
            </button>
          </div>

          <div className="incoming-message">
            {isError && <div className="error">No messages found</div>}
            {!isError && (
              <div className="wrapper">
                {isLoading ? (
                  <Skeleton count={4} height={10} width={1000} />
                ) : (
                  currentMessages.map((message) => (
                    <div key={message._id} className="message-item">
                      <input
                        type="checkbox"
                        checked={selectedMessages.includes(message._id)}
                        onChange={() => handleSelectMessage(message._id)}
                      />
                      <p>{message.fullname}</p>
                      <p>{message.email}</p>
                      <p>
                        {message.content.length > 10
                          ? `${message.content.substring(0, 30)}...`
                          : message.content}
                      </p>
                      <p>{formatDate(message.createdAt)}</p>
                      <button onClick={() => handleViewMessage(message)}>
                        View
                      </button>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          <div className="pagination-msg">
            <p>{`Showing ${indexOfFirstMessage + 1} - ${Math.min(
              indexOfLastMessage,
              totalMessagesCount
            )} of ${totalMessagesCount}`}</p>
            <div className="btn">
              <button onClick={handleClickPrev} disabled={currentPage === 1}>
                Previous
              </button>
              <button
                onClick={handleClickNext}
                disabled={indexOfLastMessage >= totalMessagesCount}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Messagess;
