import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../../context/authcontext'; 

const sendNewsletter = async ({ subject, content, token }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/sendnewsletter/send`,
    { subject, content },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useSendNewsletter = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation((data) => sendNewsletter({ ...data, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries('newsletters');
    },
  });
};
