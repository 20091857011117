import { Link, useParams } from "react-router-dom";
import { useFetchYearById } from "../../../medialibs/useFetchYearById";
import { CiCirclePlus } from "react-icons/ci";
import { Fragment, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAddFestival } from "../../../medialibs/useAddFestival";
import { ReactComponent as ImgIcon } from "../../../assets/uim_image-v.svg";
import { RxDotsVertical } from "react-icons/rx";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./addfestival.css";

const AddFestival = () => {
  const { id } = useParams();
  const { data: year, isLoading, isError, error } = useFetchYearById(id);
  const [addEventOverlay, setAddEventOverlay] = useState(false); 
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);
  const [imageCovers, setImageCovers] = useState([]);
  const addFestivalMutation = useAddFestival();

  const handleFileChange = (event) => {
    const files = event.target.files;
    setImageCovers(files);

    const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    setPreviewUrls(previews);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    Array.from(imageCovers).forEach((file) => {
      formData.append("imageCover", file);
    });

    addFestivalMutation.mutate({ year: id, formData }, {
      onSuccess: (data) => {
        setAddEventOverlay(false);
        setName("");
        setImageCovers([]);
        setPreviewUrls([]);
        console.log("Event created successfully:", data);
      },
      onError: (error) => {
        console.error("Error creating event:", error);
      },
    });
  };

  return (
    <Fragment>
      {addEventOverlay && (
        <div className="add-event-overlay">
          <div className="card">
            <h3>Add Event</h3>
            <form onSubmit={handleSubmit}>
              <label>
                Event Name
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <div className="image-card">
                <div className="in">
                  <h3>Event Cover Image</h3>
                </div>
                <div className="picker">
                  {previewUrls.length === 0 ? (
                    <Fragment>
                      <input
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        accept="image/jpeg, image/png"
                        style={{ display: "none" }}
                        id="imageCoverInput"
                      />
                      <label htmlFor="imageCoverInput">
                        <ImgIcon />
                        <span>
                          Drop your image here, or <h3>Upload</h3>
                        </span>
                        <p>Supports JPG, JPEG2000, PNG</p>
                      </label>
                    </Fragment>
                  ) : (
                    previewUrls.map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt={`Preview ${index}`}
                        className="image-preview"
                      />
                    ))
                  )}
                </div>
              </div>
              {addFestivalMutation.isError && (
                <p>Error: {addFestivalMutation.error.message}</p>
              )}
              <div className="btn">
                <button type="button" onClick={() => setAddEventOverlay(false)}>
                  Cancel
                </button>
                <button type="submit" disabled={addFestivalMutation.isLoading}>
                  {addFestivalMutation.isLoading ? "Saving..." : "Save Festival"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="addfestival-container">
        <div className="header">
          <div className="in">
            <GoArrowLeft className="icon" onClick={() => navigate("/media")} />
            <h3>{year?.year}</h3>
          </div>
          <button onClick={() => setAddEventOverlay(true)}>
            <CiCirclePlus className="icon" /> Add event
          </button>
        </div>
        {isLoading && (
          <div className="loading-card">
          <Skeleton count={4} height={100} width={250} />
          <Skeleton count={4} height={100} width={250} />
          <Skeleton count={4} height={100} width={250} />
          <Skeleton count={4} height={100} width={250} />
        </div>
        )}
        {isError && <p>Error: {error.message}</p>}
        {!isLoading && !isError && (
          <>
            <div className="festivals">
              {year.festivals.length === 0 ? (
                <p>No festivals available</p>
              ) : (
                year.festivals.map((festival) => (
                  <Link key={festival._id} to={`/festival/${festival._id}`} className="card">
                    <img src={festival.imageCover[0]} alt={festival.name} />
                    <div className="wrap">
                    <h3>{festival.name}</h3>
                    <RxDotsVertical />
                    </div>
                  </Link>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default AddFestival;
