import { useMutation } from 'react-query';

const useResetPasswordMutation = () => {
  const resetPassword = async (email) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/request-password-resset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Password reset request failed');
    }

    return response.json();
  };

  return useMutation(resetPassword);
};

export default useResetPasswordMutation;
