import { useMutation, useQueryClient } from 'react-query';
import { AuthContext } from '../../../context/authcontext';
import { useContext } from 'react';

const useUpdateSpeechNew = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const updateSpeech = async ({ id, subject, content, images, date }) => {
    try {
      const formData = new FormData();
      formData.append('subject', subject);
      formData.append('content', content);
      formData.append('date', date)

      if (images instanceof File) {
        formData.append('images', images);
      } else {
        formData.append('images', JSON.stringify(images));
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/speech/speeches/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update speech');
      }

      queryClient.invalidateQueries('new');

      return response.json();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return useMutation(updateSpeech);
};

export default useUpdateSpeechNew;

