import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/authcontext';

const addFestival = async ({ year, formData, token }) => {
  const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/media/festival/${year}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`
    },
  });
  return response.data;
};

export const useAddFestival = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  return useMutation(
    ({ year, formData }) => addFestival({ year, formData, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('years');
        queryClient.invalidateQueries('year'); 
      },
    }
  );
};
