import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../../context/authcontext";

const deleteMessages = async ({ messageIds, token }) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/msgs/delete-public-message`,
    {
      data: { messageIds }, 
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  );
  return response.data;
};

export const useDeleteMessages = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation((data) => deleteMessages({ ...data, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("messages");
    },
  });
};
