import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AuthContext } from "./context/authcontext";
import Navbar from "./navbar/navbar";
import Login from "./auth/Login/login";
import RequestRestpassword from "./auth/requestresetpassword/requestresetpassword";
import ResetPassword from "./auth/resetpassword/resetpassword";
import OneTimeVerification from "./auth/otp/onetimeverification";
import Home from "./routes/Home_routes/Home/home";
import Media from "./routes/Media_routes/home_media/media";
import CreateNews from "./routes/Home_routes/Create_news/createnews";
import AddFestival from "./routes/Media_routes/create-media/addfestival";
import AddImageGallay from "./routes/Media_routes/add_images_festival/addimagegallary";
import News from "./routes/news_routes/home/news";
import CreateNewsNow from "./routes/news_routes/create_news/createnewsnew";
import Messagess from "./routes/messages/home/messages";
import NewsLetter from "./routes/newsletter_routes/home/newsletter";
import Settings from "./routes/settings/home/settings";
import ChangePassword from "./routes/settings/changepassword/changepassword";

const Appp = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoggedIn) {
    return (
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="/media" element={<Media/>} />
          <Route path="/createnews" element={<CreateNews/>}/>
          <Route path="/year/:id" element={<AddFestival/>}/>
          <Route path="/festival/:id" element={<AddImageGallay/>}/>
          <Route path="news" element={<News/>}/>
          <Route path="createnewsnew" element={<CreateNewsNow/>}/>
          <Route path="messages" element={<Messagess/>}/>
          <Route path="newsletter" element={<NewsLetter/>}/>
          <Route path="settings" element={<Settings/>}/>
          <Route path="changepassword" element={<ChangePassword/>}/>
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/onetimeverification" element={<OneTimeVerification />} />
      <Route path="/requestresetpassword" element={<RequestRestpassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
    </Routes>
  );
};

export default Appp;
