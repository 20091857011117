import { Fragment, useState, useEffect } from "react";
import { HiXMark } from "react-icons/hi2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSendNewsletter } from "../libs/useSendNewsletter";
import useNewsletter from "../libs/useFetchNewsletters";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useFetchNewsletterSubscribers from "../libs/useFetchSubscribers";
import useDeleteNewsletters from "../libs/deleteNewsletters";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuAlertTriangle } from "react-icons/lu";
import "./newsletter.css";

function formatDate(date) {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(date).toLocaleDateString("en-GB", options);
}

function clampText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
}

const NewsLetter = () => {
  const [newsLetterOverlay, setNewsletterOverlay] = useState(false);
  const { data: subscriberData, isLoading: isLoadingSubscribers, isError: isErrorSubscribers,  } = useFetchNewsletterSubscribers();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const newsPerPage = 10;
  const [totalNewsCount, setTotalNewsCount] = useState(0);
  const [subscriber, setSubscriber] = useState(false);
  const { mutate: sendNewsletter, isLoading: sending, isError: sendError, error: sendErrorMessage } = useSendNewsletter();
  const { isLoading: fetching, isError: fetchError, data,  refetch } = useNewsletter(currentPage);
  const { mutate: deleteNewsletters, isLoading: deleting } = useDeleteNewsletters();
  const [selectedNewsletters, setSelectedNewsletters] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);

  useEffect(() => {
    if (data && data.pagination) {
      setTotalNewsCount(data.totalNewslettersCount);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendNewsletter(
      {
        subject,
        content,
      },
      {
        onSuccess: () => {
          setSubject("");
          setContent("");
          setNewsletterOverlay(false);
          refetch();
        },
      }
    );
  };

  const handleClickNext = () => {
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      refetch(`?page=${nextPage}`);
      return nextPage;
    });
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => {
      const prevPageNumber = Math.max(prevPage - 1, 1);
      refetch(`?page=${prevPageNumber}`);
      return prevPageNumber;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedNewsletters([]);
    } else {
      setSelectedNewsletters(currentNewsLetter.map((newsItem) => newsItem._id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectOne = (id) => {
    if (selectedNewsletters.includes(id)) {
      setSelectedNewsletters(selectedNewsletters.filter((newsletterId) => newsletterId !== id));
    } else {
      setSelectedNewsletters([...selectedNewsletters, id]);
    }
  };

  const handleDelete = () => {
    deleteNewsletters(selectedNewsletters, {
      onSuccess: () => {
        setSelectedNewsletters([]);
        setSelectAll(false);
        setDeleteOverlay(false);
        refetch();
      },
    });
  };

  const currentNewsLetter = data?.newsletters || [];
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;

  return (
    <Fragment>
      {deleteOverlay && (
        <div className="delete-overlay-newsl">
          <div className="overlay-content">
            <LuAlertTriangle className="icon" />
            <h3>Delete</h3>
            <p>Are you sure you want to delete the selected newsletters?</p>
            <div className="buttons">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDelete} disabled={deleting}>
                {deleting ? "Deleting..." : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
      {subscriber && (
        <div className="subscriber-container">
          <div className="header">
            <h3>Subscribers ({subscriberData?.totalSubscribers})</h3>
            <HiXMark onClick={() => setSubscriber(false)} className="icon" />
          </div>
          <div className="card-container">
            {isLoadingSubscribers && <Skeleton count={2} height={10} width={350} />}
            {isErrorSubscribers && <div className="error">No subscriber found.</div>}
            {subscriberData && subscriberData.subscribers.map((subscriber) => (
              <div key={subscriber._id} className="subscriber-card">
                <p>{subscriber.email}</p>
                <h3>{formatDate(subscriber.createdAt)}</h3>
              </div>
            ))}
          </div>
        </div>
      )}
      {newsLetterOverlay && (
        <div className="create-newsletter">
          <div className="card">
            <div className="header">
              <h3>New newsletter</h3>
              <HiXMark onClick={() => setNewsletterOverlay(false)} className="icon" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="wrap">
                <input
                  type="text"
                  placeholder="Title"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <ReactQuill
                  theme="snow"
                  placeholder="Type newsletter here"
                  value={content}
                  onChange={setContent}
                  required
                />
              </div>
              <div className="in">
                <button type="submit" disabled={sending}>
                  {sending ? "Sending..." : "Send"}
                </button>
              </div>
              {sendError && <p className="error">Error: {sendErrorMessage.message}</p>}
            </form>
          </div>
        </div>
      )}
      <div className="news-letter-con">
        <div className="header">
          <h3>Newsletters</h3>
          <div className="in">
            <button onClick={() => setSubscriber(true)}>Subscribers</button>
            <button onClick={() => setNewsletterOverlay(true)}>Create</button>
          </div>
        </div>
        <div className="newsletter-table">
        {fetchError && (
          <div className="error">No newsletter found</div>
        )}
          <div className="header">
            <div className="wrap">
              <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
              <p>Select all</p>
            </div>
            <RiDeleteBinLine
              onClick={() => selectedNewsletters.length > 0 && setDeleteOverlay(true)}
              disabled={selectedNewsletters.length === 0}
              className="icon"
            />
          </div>
          {!fetchError && (
            <div className="wrapper">
              {fetching ? (
                <Skeleton count={4} height={50} />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Topic</th>
                      <th>Content</th>
                      <th>Sent At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentNewsLetter.map((newsItem) => (
                      <tr key={newsItem._id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedNewsletters.includes(newsItem._id)}
                            onChange={() => handleSelectOne(newsItem._id)}
                          />
                        </td>
                        <td>{newsItem.subject}</td>
                        <td dangerouslySetInnerHTML={{ __html: clampText(newsItem.content.split("</p>")[0], 50) + "</p>" }} />
                        <td>{newsItem.sentAt ? formatDate(newsItem.sentAt) : ""}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
          <div className="pagination-sub">
            <p>{`Showing ${indexOfFirstNews + 1} - ${Math.min(indexOfLastNews, totalNewsCount)} of ${totalNewsCount}`}</p>
            <div className="btn">
              <button onClick={handleClickPrev} disabled={currentPage === 1}>
                Previous
              </button>
              <button onClick={handleClickNext} disabled={indexOfLastNews >= totalNewsCount}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewsLetter;
