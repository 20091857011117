import { createContext, useState } from "react";

export const GlobalContext = createContext({
  isMobile: false,
  isShow: false,
  isLoading: false,
  error: null
});

export const GlobalProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState(null);
  const value = { isMobile, setIsMobile, isShow, setIsShow, isLoading, setIsloading, error, setError };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
