import { useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../context/authcontext";
import { useNavigate } from "react-router-dom";

const useCreateNews = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const createNewsMutation = useMutation(
    (formData) =>
      fetch(`${process.env.REACT_APP_BACKEND_URL}/new/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("speeches");
        navigate("/")
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const createNews = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      await createNewsMutation.mutateAsync(formData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    createNews,
    loading,
    error,
  };
};

export default useCreateNews;
