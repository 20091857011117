import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; 

export const encryptToken = (token) => {
  return CryptoJS.AES.encrypt(token, secretKey).toString();
};

export const decryptToken = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
