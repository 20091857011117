import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom'; 

const login = async ({ email, password, otp }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, otp }),
  });

  if (!response.ok) {
    const error = await response.json();
    if (error.message === "One-time verification code (OTP) is required to proceed.") {
      throw new Error("OTP required");
    } else {
      throw new Error("Invalid credentials, password or email is incorrect.");
    }
  }

  return response.json();
};

const useLogin = () => {
  const navigate = useNavigate();
  const mutation = useMutation(login);

  const mutate = async (data) => {
    try {
      const result = await mutation.mutateAsync(data);
      return result;
    } catch (error) {
      if (error.message === 'OTP required') {
        navigate("/onetimeverification"); 
      } else {
        throw error; 
      }
    }
  };

  return { mutate, isLoading: mutation.isLoading, error: mutation.error };
};

export default useLogin;
