import { NavLink as Link, Outlet } from "react-router-dom";
import { Fragment, useContext } from "react";
import {AuthContext} from "../context/authcontext"
import { useGetCurrentUser } from "../lib/fetchCurrentUser";
import { ReactComponent as LogoIcon } from "../assets/Vector.svg";
import { ReactComponent as PersonIcon } from "../assets/bi_person.svg";
import { CiMicrophoneOff } from "react-icons/ci";
import { GoFileMedia } from "react-icons/go";
import { PiNewspaperLight } from "react-icons/pi";
import { BiMessageDots } from "react-icons/bi";
import { BiMessage } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import { HiPower } from "react-icons/hi2";
import "./navbar.css";

const Navbar = () => {
  const { data: currentUserData } = useGetCurrentUser();
  const { Logout, logoutMutation } = useContext(AuthContext);

  const NavLinkLCss = ({ isActive }) => ({
    backgroundColor: isActive ? "white" : "",
    color: isActive ? "#254a38" : "",
  });

  return (
    <Fragment>
      <div className="admin_header-container">
        <p>
          Welcome back, <span>{currentUserData?.first_name}</span>{" "}
          <span>{currentUserData?.last_name}</span>
        </p>
        <div className="side">
          <PersonIcon className="round" />
        </div>
      </div>
      <div className="navbar-container">
        <div className="nav-links">
          <button className="logo">
            <LogoIcon />
          </button>
          <Link to="/" style={NavLinkLCss}>
            <CiMicrophoneOff className="icon" /> Speeches
          </Link>
          <Link to="/media" style={NavLinkLCss}>
            <GoFileMedia className="icon" /> Media
          </Link>
          <Link to="/news" style={NavLinkLCss}>
            <PiNewspaperLight className="icon" /> News
          </Link>
          <Link to="/messages" style={NavLinkLCss}>
            <BiMessageDots className="icon" /> Messages
          </Link>
          <Link to="/newsletter" style={NavLinkLCss}>
            <BiMessage className="icon" /> News Letter
          </Link>
          <Link to="/settings" style={NavLinkLCss}>
            <CiSettings className="icon" /> Settings
          </Link>
        </div>
        <button onClick={Logout} disabled={logoutMutation.isLoading}>
          <HiPower className="icon" /> {logoutMutation.isLoading ? "Goodbye..." : "Log out"}
        </button>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navbar;
