import { GoArrowLeft } from "react-icons/go";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { useFetchFestivalById } from "../../../medialibs/useFetchFestivalById";
import { useAddImageToFestival } from "../../../medialibs/useAddImageGallary";
import { ReactComponent as ImgIcon } from "../../../assets/uim_image-v.svg";
import { useFetchImageById } from '../../../medialibs/useFetchImageById';
import useDeleteImages from "../../../medialibs/useDeleteImages";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GoAlertFill } from "react-icons/go";
import "./addimagegallary.css";

const AddImageGallary = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error } = useFetchFestivalById(id);
  const navigate = useNavigate();
  const [addImageGalOverlay, setAddImageGalOverlay] = useState(false);
  const [galleryUrls, setGalleryUrls] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const addImageFestivalMutation = useAddImageToFestival();
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const mutation = useDeleteImages();

  const handleDelete = () => {
    mutation.mutate(selectedImageIds, {
      onSuccess: () => {
        setDeleteOverlay(false);
        setSelectedImageIds([]);
      }
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setGalleryUrls(files);

    const previews = Array.from(files).map((file) => URL.createObjectURL(file));
    setPreviewUrls(previews);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    Array.from(galleryUrls).forEach((file) => {
      formData.append("gallaryUrl", file);
    });

    addImageFestivalMutation.mutate(
      { festivalId: id, formData },
      {
        onSuccess: (data) => {
          setAddImageGalOverlay(false);
          setGalleryUrls([]);
          setPreviewUrls([]);
          console.log("Images added successfully:", data);
        },
        onError: (error) => {
          console.error("Error adding images:", error);
        },
      }
    );
  };

  const handleCheckboxChange = (imageId) => {
    setSelectedImageIds(prevState =>
      prevState.includes(imageId)
        ? prevState.filter(id => id !== imageId)
        : [...prevState, imageId]
    );
  };

  return (
    <Fragment>
      {deleteOverlay && (
        <div className="delete-images">
          <div className="card">
            <GoAlertFill className="icon" />
            <h3>Delete</h3>
            <p>Are you sure want to delete selected images?</p>
            <div className="btn">
              <button onClick={() => setDeleteOverlay(false)}>No</button>
              <button onClick={handleDelete} disabled={mutation.isLoading}>
                {mutation.isLoading ? 'Deleting...' : 'Delete'}
              </button>
            </div>
            {mutation.isError && <p>Error: {mutation.error.message}</p>}
            {mutation.isSuccess && <p>Images deleted successfully!</p>}
          </div>
        </div>
      )}
      {addImageGalOverlay && (
        <div className="imagegallary-overlay">
          <div className="card">
            <h3>Add images</h3>
            <form onSubmit={handleSubmit}>
              <div className="picker">
                {previewUrls.length === 0 ? (
                  <Fragment>
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                      id="imageCoverInput"
                    />
                    <label htmlFor="imageCoverInput">
                      <ImgIcon />
                      <span>
                        Drop your images here, or <h3>Upload</h3>
                      </span>
                      <p>Supports JPG, JPEG2000, PNG</p>
                    </label>
                  </Fragment>
                ) : (
                  <div className="prev">
                    {previewUrls.map((url, index) => (
                      <img
                        key={index}
                        src={url}
                        alt={`Preview ${index}`}
                        className="image-preview"
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="btn">
                <button
                  type="button"
                  onClick={() => {
                    setAddImageGalOverlay(false);
                    setPreviewUrls([]);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" disabled={addImageFestivalMutation.isLoading}>
                  {addImageFestivalMutation.isLoading ? "Saving..." : "Save Images"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="add-image-gallary">
        <div className="header">
          <div className="in">
            <GoArrowLeft className="icon" onClick={() => navigate(-1)} />
            <h3>{data?.name}</h3>
          </div>
          {selectedImageIds.length > 0 ? (
            <button onClick={() => setDeleteOverlay(true)} className="delete-btn">delete</button>
          ) : (
            <button onClick={() => setAddImageGalOverlay(true)}>
              <CiCirclePlus className="icon" /> Add image gallery
            </button>
          )}
        </div>
        <div className="image-card">
          {isLoading && (
            <div className="loading-card">
              <Skeleton count={4} height={100} width={250} />
              <Skeleton count={4} height={100} width={250} />
              <Skeleton count={4} height={100} width={250} />
              <Skeleton count={4} height={100} width={250} />
            </div>
          )}
          {!isLoading && isError && <p>{error.message}</p>}
          {data?.imageGallery?.length > 0 ? (
            data.imageGallery.map((imageId, index) => (
              <ImageCard
                key={index}
                imageId={imageId}
                onCheckboxChange={handleCheckboxChange}
                isSelected={selectedImageIds.includes(imageId)}
              />
            ))
          ) : (
            !isLoading && <p>No images available</p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const ImageCard = ({ imageId, onCheckboxChange, isSelected }) => {
  const { data, isLoading, isError, error } = useFetchImageById(imageId);

  if (isLoading) {
    return <p>Getting images</p>
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  return (
    <div className="card-gallary">
      <img src={data.galleryUrl} alt={`Gallery`} />
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => onCheckboxChange(imageId)}
      />
    </div>
  );
};

export default AddImageGallary;


