import { useGetCurrentUser } from "../../../lib/fetchCurrentUser";
import { Link } from "react-router-dom";
import "./settings.css"



const Settings = () => {
    const { data: currentUserData } = useGetCurrentUser();
    return ( 
        <div className="settings-container">
            <div className="header">
                <h3>Settings</h3>
            </div>
            <div className="data-card">
                <span>
                    <p>First Name</p>
                    <p>{currentUserData?.first_name}</p>
                </span>
                <span>
                    <p>Last Name</p>
                    <p>{currentUserData?.last_name}</p>
                </span>
                <span>
                    <p>Email</p>
                    <p>{currentUserData?.email}</p>
                </span>
                <span>
                    <p>Password</p>
                    <Link to='/changepassword'>Change password</Link>
                </span>
            </div>
        </div>
     );
}
 
export default Settings;