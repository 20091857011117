import React, { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from 'axios';
import { useMutation } from 'react-query';
import { useGetCurrentUser } from "../lib/fetchCurrentUser";
import { encryptToken, decryptToken } from "../utils/Encyption/crypto";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  token: null,
  Login: () => {},
  Logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const { data: currentUserData, isLoading: isCurrentUserLoading } = useGetCurrentUser(token);

  const Login = useCallback((user) => {
    setIsLoggedIn(true);
    setUser(user);
    const encryptedToken = encryptToken(user.token);
    setToken(encryptedToken);
    Cookies.set("authToken", encryptedToken, { expires: 9 / 24 });
  }, []);

  useEffect(() => {
    const authToken = Cookies.get("authToken");

    if (authToken) {
      const decryptedToken = decryptToken(authToken);
      setToken(decryptedToken);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setUser(null);
      setToken(null);
      Cookies.remove("authToken");
    }
  }, [navigate]);

  useEffect(() => {
    if (!isCurrentUserLoading && currentUserData) {
      setUser(currentUserData);
    }
  }, [isCurrentUserLoading, currentUserData]);

  const logoutApiCall = async (token) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/logout`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  };

  const setAuthState = ({ isLoggedIn, user, token }) => {
    setIsLoggedIn(isLoggedIn);
    setUser(user);
    setToken(token);
  };

  const logoutMutation = useMutation(() => logoutApiCall(token), {
    onSuccess: () => {
      setAuthState({ isLoggedIn: false, user: null, token: null });
      Cookies.remove('authToken');
      navigate("/");
    },
    onError: (error) => {
      console.error('Logout failed:', error);
    }
  });

  const Logout = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  const value = {
    isLoggedIn,
    user,
    token,
    Login,
    Logout,
    logoutMutation
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
