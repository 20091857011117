import React, { Fragment, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useCreateSpeeches from "../libs/useCreateSpeeches";
import { IoImagesOutline } from "react-icons/io5";
import Spinner from "../../../utils/Loader/spinner";


const CreateNewsNow = () => {
  const navigate = useNavigate();
  const { createNews, loading, error } = useCreateSpeeches();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [images, setImages] = useState(null);

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("content", content);
    formData.append("date", date);
    formData.append("images", images);

    try {
      await createNews(formData);
    } catch (error) {
      console.error("Error creating news:", error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImages(file);

    // Create a preview URL for the selected image
    const previewURL = URL.createObjectURL(file);
    setImagePreview(previewURL);
  };

  const handleSelectImage = () => {
    // Trigger file selection when the button is clicked
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = handleImageChange;
    input.click();
  };

  return (
    <Fragment>
      {overlay && (
        <div className="new-overlay-container">
          <div className="card">
            <h3>Publish</h3>
            <label>
              Select Date & Year
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </label>

            <div className="btn">
              <button onClick={() => setOverlay(false)}>Cancel</button>
              <button disabled={loading} onClick={handleSubmit}>
                {loading ? <Spinner/> : "Publish"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="create-news-container">
        <div className="header">
          <div className="wrap">
            <IoArrowBackOutline
              onClick={() => navigate("/news")}
              className="icon"
            />
            <h3>New News</h3>
          </div>
          <button onClick={() => setOverlay(true)}>Publish</button>
        </div>
        <div className="create-news">
          <form>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <ReactQuill
              theme="snow" 
              value={content}
              onChange={handleChange}
              placeholder="Write your speech here..."
            />

            {imagePreview && (
              <img src={imagePreview} alt="Preview" className="image-preview" />
            )}

            {!imagePreview && (
              <div className="wrap">
                <IoImagesOutline className="logo" />
                <button type="button" onClick={handleSelectImage}>Upload Image</button>
              </div>
            )}
          </form>
          {error && <p>{error.message}</p>}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateNewsNow;

