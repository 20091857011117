import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/authcontext';
import { useContext } from 'react';

const useFetchNewsletterSubscribers = () => {
  const { token } = useContext(AuthContext);

  return useQuery('newsletterSubscribers', async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/newsletter/subscribers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch newsletter subscribers');
    }

    return response.json();
  });
};

export default useFetchNewsletterSubscribers;
